import React, { createContext, useReducer } from 'react';
import socketIOClient from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import Peer from 'peerjs';
import { v4 as uuidv4 } from 'uuid'
import { peerReducer } from './peerReducer'
import { addPeerAction, removePeerAction } from './peerActions';

const WS = "https://call-api.hostless.app"

export const RoomContext = createContext<null | any>(null);

const ws = socketIOClient(WS);


export const RoomProvider = ({ children }: { children: React.ReactNode}) => {
    const navigate = useNavigate();
    const [me, setMe] = React.useState<Peer>();


    const [peers, dispatch] = useReducer(peerReducer, {});

    const [stream, setStream] = React.useState<MediaStream>()

    const [screenSharingId, setScreenSharingId] = React.useState<string | null>()

    const [connections, setConnections] = React.useState<Record<string, any>>({})

    const [roomId, setRoomId] = React.useState('')


    const enterRoom = ({ roomId }: any) => {
        navigate(`/room/${roomId}`)
    }

    const getUsers = ({ participants, roomId}: any) => {
        console.log(participants, roomId)
    }

    const removePeer = (peerId: string) => {
        dispatch(removePeerAction(peerId))
    }

    const switchScreen = (stream: MediaStream) => {
        setStream(stream);
        setScreenSharingId(me?.id || null)

        console.log({connections})
        Object.values(connections).forEach((connection) => {
            const videoTrack = stream?.getTracks().find(track => track.kind === 'video');
            console.log({connection})
            connection.getSenders()[1].replaceTrack(videoTrack).catch((err:any) => console.log(err));

        })
    }

    const shareScreen = () => {
        if(screenSharingId) {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true}).then(switchScreen);
        } else {
            navigator.mediaDevices.getDisplayMedia().then(switchScreen)
        }
    }

    const peerConnected = ({ peerId, connection }: any) => {
        if (!connections[peerId]) {
            console.log({ peerConnected: true, peerId, connection })
            setConnections({ ...connections, [peerId]: connection })
        }
    }

    React.useEffect(() => {
        const meId = uuidv4();

        const peer = new Peer(meId, { host:'peer.hostless.app' })


        console.log({ peerId: peer.id, peer })
    
        setMe(peer);

        try {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true}).then((stream) => {
                setStream(stream)
            });
        } catch (error) {
            console.error(error)
        }
    
        ws.on('room-created', enterRoom)
        ws.on('get-users', getUsers)
        ws.on('user-disconnected', removePeer)
        ws.on('user-started-sharing', (peerId) => setScreenSharingId(peerId))
        ws.on('user-stopped-sharing', () => setScreenSharingId(''))

        return () => {
            ws.off('user-joined')
            ws.off('room-created')
            ws.off('get-users')
            ws.off('user-disconnected')
            ws.off('user-started-sharing')
            ws.off('user-stopped-sharing')
        }
    }, []);

    React.useEffect(() => {
        console.log({ me, stream })
        if (!me) return;
        if (!stream) return;
        

        ws.on('user-joined', ({ peerId }) => {
            console.log('calling peers', stream, me, peerId)
            const call = me.call(peerId, stream)
            call.on('stream', (peerStream) => {
                console.log('dispatching add peer to ', peerId) 
                dispatch(addPeerAction(peerId, peerStream))
                peerConnected({ peerId: me.id, connection: call.peerConnection })
            })
        })

        me.on('call', (call) => {
            console.log('receiving call', stream, me)
            call.answer(stream)
            call.on('stream', (peerStream) => {
                console.log('dispatching add peer to ', call.peer)
                dispatch(addPeerAction(call.peer, peerStream))
                peerConnected({ peerId: me.id, connection: call.peerConnection })
            })
        })
    }, [me, stream])

    React.useEffect(() => {
        if (screenSharingId) {
            ws.emit('start-sharing', { peerId: screenSharingId, roomId, })
        } else {
            ws.emit('stop-sharing')
        }
    }, [screenSharingId, roomId])

    console.log({peers})
    return (<RoomContext.Provider value={{ ws, me, stream, peers, shareScreen, screenSharingId, setRoomId }}>{children}</RoomContext.Provider>)
}