import React, { useEffect } from "react"

export const VideoPlayer: React.FC<{stream: MediaStream}> = ({ stream }) => {
    const videoRef = React.useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current) videoRef.current.srcObject = stream
    }, [stream])
    return (
        <video ref={videoRef} autoPlay muted/>
    )
}